import { observer } from 'mobx-react'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ApplicationSwitch from './ApplicationSwitch'
import RootStore from './stores/RootStore'
import RootStoreProvider, { useRootStore } from './stores/RootStoreContext'
import './styles/App.scss'

const ENABLE_DEBUG_OBSERVER = true

const StoreDebugHelperInner = (props: { rootStore: RootStore }) => <></>
const StoreDebugHelper = observer(() => {
  const rootStore = useRootStore()
  return <StoreDebugHelperInner rootStore={rootStore} />
})

const App = () => {
  return (
    <RootStoreProvider>
      {ENABLE_DEBUG_OBSERVER && <StoreDebugHelper />}
      <Router>
        <div className='App'>
          <ApplicationSwitch />
        </div>
      </Router>
    </RootStoreProvider>
  )
}

export default App

import Task from '../model/Task'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import {
  DEFAULT_HEADERS,
  DEFAULT_ERROR,
  FABRIC_BASE_URL,
} from './NetworkingConstants'

const tasksForOrganisation = async (organisationIdentifier: string) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/tasks${accessQueryParameters(
        organisationIdentifier
      )}`,
    DEFAULT_HEADERS
  )
  const body = await response.json()
  if (!response.ok || !body.tasks) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.tasks as Task[]
}

const addTask = async (organisationIdentifier: string, task: Task) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/task${accessQueryParameters(
        organisationIdentifier
      )}`,
    {
      ...DEFAULT_HEADERS,
      method: 'POST',
      body: JSON.stringify(task),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as Task
}

const updateTaskWithId = async (
  taskId: string,
  organisationIdentifier: string,
  task: Partial<Task>
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/task/${taskId}${accessQueryParameters(
        organisationIdentifier
      )}`,
    {
      ...DEFAULT_HEADERS,
      method: 'PATCH',
      body: JSON.stringify(task),
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as Task
}

const deleteTaskWithId = async (
  taskId: string,
  organisationIdentifier: string
) => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/datalake/task/${taskId}${accessQueryParameters(
        organisationIdentifier
      )}`,
    {
      ...DEFAULT_HEADERS,
      method: 'DELETE',
    }
  )
  const body = await response.json()
  if (!response.ok || !body.task) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.task as Task
}

const Tasks = {
  tasksForOrganisation,
  addTask,
  updateTaskWithId,
  deleteTaskWithId,
}

export default Tasks

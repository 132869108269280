import { observer } from 'mobx-react'
import { REDIRECT_URL } from '../networking/NetworkingConstants'
import { useRootStore } from '../stores/RootStoreContext'

const NotAuthenticatedPage = () => {
  const { authenticationStore } = useRootStore()
  if (authenticationStore.isHydrated && !authenticationStore.isAuthenticated) {
    document.location.href = REDIRECT_URL
  }
  return null
}

export default observer(NotAuthenticatedPage)

import { KeyValueStore } from './types'

export const linkify = (link: string) => {
  const escapedLink = preventXSS(link)
  if (
    escapedLink.indexOf('http://') === 0 ||
    escapedLink.indexOf('https://') === 0
  ) {
    return escapedLink
  } else return `https://${escapedLink}`
}

export const preventXSS = (link: string) => {
  // eslint-disable-next-line no-script-url
  return link.replaceAll('javascript:', '')
}

export const areStringsEmpty = (keyValueStore: KeyValueStore) => {
  let hasContent = false
  Object.keys(keyValueStore).forEach((key) => {
    if (keyValueStore[key] !== '') hasContent = true
  })
  return !hasContent
}

export const areIdentical = (
  a: KeyValueStore,
  b: KeyValueStore,
  shouldReturn?: boolean
): boolean => {
  if (!a || !b) return false
  for (const key in a) {
    if (!a.hasOwnProperty(key)) continue

    const areDifferent = a[key] !== b[key]
    const areBothEmpty = !a[key] && !b[key]

    if (areDifferent && !areBothEmpty) return false
  }
  if (shouldReturn) return true
  else return areIdentical(b, a, true)
}

export const truncateString = (string: string | undefined, limit: number) => {
  if (!string) return

  if (string.length > limit) return string.slice(0, limit) + '...'
  else return string
}

export const centsToCHF = (
  amount: number | undefined,
  shouldDisplayNegative?: boolean
) => {
  if (!amount) return '0.00'
  const chf = new Intl.NumberFormat('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format((shouldDisplayNegative ? -amount : amount) / 100)
    .replaceAll(',', "'")

  return chf
}

const Utilities = {
  linkify,
  preventXSS,
  areStringsEmpty,
  areIdentical,
  truncateString,
  centsToCHF,
}

export default Utilities

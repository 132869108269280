import React from 'react'
import SingleContactTab from '../../../contacts/single-contact-tab/SingleContactTab'
import './ClientDetailView.scss'

interface ClientDetailViewProps {
  wasContactFound: boolean
}

const ClientDetailView = (props: ClientDetailViewProps) => (
  <div className='client-detail-view'>
    <SingleContactTab wasContactFound={props.wasContactFound} />
  </div>
)

export default ClientDetailView

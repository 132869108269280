import { Text } from '@nextbusiness/infinity-ui'

interface ListSegmentHeaderProps {
  letter: string
}

const ListSegmentHeader = (props: ListSegmentHeaderProps) => (
  <div className='contacts-list-segment-header'>
    <Text type='inline' className='contacts-list-segment-title'>
      {props.letter}
    </Text>
    <div className='contacts-list-segment-divider'></div>
  </div>
)

export default ListSegmentHeader

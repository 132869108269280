import { FeedbackBanner, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import contactDialogStore from '../../stores/ContactDialogStore'
import ContactFieldCategory from './fields/ContactFieldCategory'
import ContactFieldKeywords from './fields/ContactFieldKeywords'
import ContactFieldList from './fields/ContactFieldList'

interface ContactViewerProps {
  localErrorMessage: string | undefined
}

const ContactViewer = (props: ContactViewerProps) => {
  const { activeContact } = contactDialogStore

  return (
    <>
      <Flex direction='vertical' className='contact-filter'>
        {activeContact?.keywords && <ContactFieldKeywords />}
        <ContactFieldCategory />
      </Flex>
      <Flex direction='vertical' className='contact-info'>
        <ContactFieldList />
      </Flex>
      {props.localErrorMessage && (
        <FeedbackBanner variant='error'>
          {props.localErrorMessage}
        </FeedbackBanner>
      )}
    </>
  )
}

export default observer(ContactViewer)

import { Card, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import contactDialogStore from '../../../stores/ContactDialogStore'
import KeyValuePair from '../KeyValuePair'

const ContactFieldKeywords = () => {
  const { activeContact } = contactDialogStore

  return (
    <KeyValuePair
      fieldKey='Schlüsselwörter'
      fieldValue={
        <Flex className='keyword-wrapper'>
          {activeContact?.keywords?.map((keyword) => (
            <Card className='keyword-card' elevation='none' key={keyword}>
              {keyword}
            </Card>
          ))}
        </Flex>
      }
    />
  )
}

export default observer(ContactFieldKeywords)

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useAsyncEffect = (
  effect: (isMounted: () => boolean) => any | Promise<any>,
  destroy?: (result?: any) => void
) => {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(function () {
    var result: any
    var mounted = true
    var maybePromise = effect(function () {
      return mounted
    })

    Promise.resolve(maybePromise).then(function (value) {
      result = value
    })

    return function () {
      mounted = false

      if (hasDestroy && destroy) {
        destroy(result)
      }
    }
  })
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item
        ? key.includes('date')
          ? new Date(JSON.parse(item))
          : JSON.parse(item)
        : initialValue
    } catch (error: any) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error: any) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
export function useSessionStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item
        ? key.includes('date')
          ? new Date(JSON.parse(item))
          : JSON.parse(item)
        : initialValue
    } catch (error: any) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error: any) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

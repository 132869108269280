import {
  Button,
  Flex,
  InputField,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import ContactsListSegment from '../../../contacts/list-tab/ContactsListSegment'
import contactDialogStore from '../../../stores/ContactDialogStore'
import { useRootStore } from '../../../stores/RootStoreContext'
import './ClientsSidebar.scss'

interface ClientsSidebarProps {
  wasContactFound?: boolean
  setWasContactFound: (wasFound: boolean) => void
}

const ClientsSidebar = (props: ClientsSidebarProps) => {
  const { contactStore } = useRootStore()
  const { mode, setMode, setActiveContact, setDraftContact, blankContact } =
    contactDialogStore

  const [searchValue, setSearchValue] = useState<string>('')

  return (
    <div className='clients-sidebar'>
      <div className='contacts-list'>
        <Flex
          direction='vertical'
          className='list-content'
          verticalAlignment='flex-start'
        >
          <InputField
            value={searchValue}
            onChange={setSearchValue}
            className='contacts-search'
            icon='search'
            placeholder='Suche'
          />
          <Button
            iconLeft='plus'
            variant='quaternary'
            className='new-contact-button'
            onClick={() => {
              setDraftContact(blankContact)
              setMode('creating')
              setActiveContact(undefined)
            }}
            disabled={mode !== 'viewing'}
          >
            Neuer Kontakt
          </Button>
          {contactStore.allContacts.length > 0 ? (
            <ContactsListSegment
              setWasContactFound={props.setWasContactFound}
              searchQuery={searchValue}
            />
          ) : (
            <NonIdealState title='Noch keine Kontakte'>
              Erstelle zuerst einen Kontakt
            </NonIdealState>
          )}
        </Flex>
      </div>
    </div>
  )
}

export default observer(ClientsSidebar)

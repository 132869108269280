export const BASE_URL = 'https://infinity-crm.azurewebsites.net'
export const FABRIC_BASE_URL = 'https://infinity-fabric.azurewebsites.net'

export const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export const isRunningInDevelopmentEnvironment =
  document.location.hostname === 'localhost'

export const ORIGIN = 'https://staging.infinity.nextbusiness.ch'
export const REDIRECT_URL =
  ORIGIN +
  '/app/ch.nextbusiness.infinity.crm' +
  (isRunningInDevelopmentEnvironment ? '?modifier=development-mode' : '')
export const DASHBOARD_URL = ORIGIN + '/dashboard'

export const DEFAULT_ERROR = 'Ein Fehler ist aufgetreten'

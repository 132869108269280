import { MentionAtomPopupComponent, MentionAtomState } from '@remirror/react'
import { useMemo, useState } from 'react'
import { IdentifierSchemaAttributes } from 'remirror'
import { MentionAtomNodeAttributes } from 'remirror/extensions'
import './EditorMentions.scss'

export const extraAttributes: IdentifierSchemaAttributes[] = [
  {
    identifiers: ['mention', 'emoji'],
    attributes: { role: { default: 'presentation' } },
  },
  { identifiers: ['mention'], attributes: { href: { default: null } } },
]

export interface MentionComponentProps<
  UserData extends MentionAtomNodeAttributes = MentionAtomNodeAttributes
> {
  users?: UserData[]
  tags?: string[]
}

function MentionComponent({ users, tags }: MentionComponentProps) {
  const [mentionState, setMentionState] = useState<MentionAtomState | null>()
  const tagItems = useMemo(
    () => (tags ?? []).map((tag) => ({ id: tag, label: `#${tag}` })),
    [tags]
  )
  const items = useMemo(() => {
    if (!mentionState) {
      return []
    }

    const allItems = mentionState.name === 'at' ? users : tagItems

    if (!allItems) {
      return []
    }

    const query = mentionState.query.full.toLowerCase() ?? ''
    return allItems
      .filter((item) => item.label.toLowerCase().includes(query))
      .sort()
  }, [mentionState, users, tagItems])

  return (
    <MentionAtomPopupComponent
      onChange={setMentionState}
      items={items}
      ZeroItemsComponent={() => null}
    />
  )
}

export default MentionComponent

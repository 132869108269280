import { Avatar, Flex, InputField, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import './ProfileHeader.scss'

const ProfileHeader = () => {
  const { contactStore } = useRootStore()

  const { mode, activeContact, draftContact, setDraftContact } =
    contactDialogStore

  return (
    <Flex className='contact-header' verticalAlignment='flex-start'>
      {(activeContact || mode === 'creating') && (
        <div className='contact-picture-wrapper'>
          <div className='contact-picture'>
            <Avatar
              name={
                mode === 'viewing' && activeContact
                  ? contactStore.nameOfContact(activeContact)
                  : `${draftContact['firstname']} ${draftContact['lastname']}`
              }
            />
          </div>
        </div>
      )}

      <Flex direction='vertical' className='contact-summary'>
        {mode === 'editing' || mode === 'creating' ? (
          <>
            <Flex spacing='small' className='edit-field-name'>
              <InputField
                className='firstname'
                value={draftContact['firstname']}
                onChange={(value: string) =>
                  setDraftContact({ ...draftContact, firstname: value })
                }
                placeholder='Vorname'
              />
              <InputField
                className='lastname'
                value={draftContact['lastname']}
                onChange={(value: string) =>
                  setDraftContact({ ...draftContact, lastname: value })
                }
                placeholder='Nachname'
              />
            </Flex>
            <Flex direction='vertical'>
              <InputField
                className='edit-field'
                value={draftContact['companyName']}
                onChange={(value: string) =>
                  setDraftContact({ ...draftContact, companyName: value })
                }
                placeholder='Firma'
              />
              <InputField
                className='edit-field'
                value={draftContact['position']}
                onChange={(value: string) =>
                  setDraftContact({ ...draftContact, position: value })
                }
                placeholder='Position'
              />
            </Flex>
          </>
        ) : (
          activeContact && (
            <>
              <Text type='paragraph' className='contact-name'>
                {contactStore.nameOfContact(activeContact)}
              </Text>
              {activeContact.companyName &&
                (activeContact.firstname || activeContact.lastname) && (
                  <Text type='inline' className='contact-job-info'>
                    {activeContact.companyName}
                  </Text>
                )}
              {activeContact.position && (
                <Text type='inline' className='contact-job-info'>
                  {activeContact.position}
                </Text>
              )}
            </>
          )
        )}
      </Flex>
    </Flex>
  )
}

export default observer(ProfileHeader)

import { Button, Flex, Tooltip } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Contact } from '../../model/Contact'
import Contacts from '../../networking/Contacts'
import { ErrorType } from '../../networking/Errors'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import { KeyValueStore } from '../../utility/types'
import { AbortIntent } from '../ContactsDialog'
import AbortEditDialog from './AbortEditDialog'
import ConfirmDeleteContact from './ConfirmDeleteContact'
import './SingleContactFooter.scss'

interface SingleContactFooterProps {
  createContact: (newContact: KeyValueStore) => void
  setLocalErrorMessage: (localErrorMessage: string | undefined) => void
  onContactPicked?: (contact: Contact) => void
  missingName: boolean
}

const SingleContactFooter = (props: SingleContactFooterProps) => {
  const { authenticationStore, contactStore } = useRootStore()
  const { cancelAbort } = contactDialogStore

  const {
    mode,
    setMode,
    activeContact,
    setActiveContact,
    draftContact,
    setDraftContact,
    reasonForAborting,
    abortEditing,
  } = contactDialogStore

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const editContact = async (newContact: KeyValueStore) => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    if (!activeContact)
      return props.setLocalErrorMessage('Kein Kontakt ausgewählt.')

    try {
      setActiveContact(
        await Contacts.editContact(
          authenticationStore.organisationIdentifier,
          activeContact.id!,
          authenticationStore.applicationAccessToken,
          newContact as unknown as Contact
        )
      )
      contactStore.loadContacts()
      props.setLocalErrorMessage(undefined)
      setMode('viewing')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.InsufficientPermissions:
          return props.setLocalErrorMessage(
            'Finance hat keine Berechtigung, Kontakte zu erstellen oder bearbeiten'
          )
        case ErrorType.NotFound:
          return props.setLocalErrorMessage(error.message)
        default:
          props.setLocalErrorMessage(error.message)
      }
    }
  }

  const deleteContact = async () => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    if (!activeContact)
      return props.setLocalErrorMessage('Kein Kontakt ausgwählt.')
    try {
      await Contacts.deleteContact(
        authenticationStore.organisationIdentifier,
        activeContact.id!,
        authenticationStore.applicationAccessToken
      )

      setActiveContact(undefined)
      contactStore.loadContacts()
      props.setLocalErrorMessage(undefined)
      setMode('viewing')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.InsufficientPermissions:
          return props.setLocalErrorMessage(
            'Finance hat keine Berechtigung, Kontakte zu erstellen oder bearbeiten'
          )
        case ErrorType.NotFound:
          return props.setLocalErrorMessage(error.message)
        default:
          props.setLocalErrorMessage(error.message)
      }
    }
  }

  return (
    <>
      {mode === 'editing' || mode === 'creating' ? (
        <>
          <Flex
            className='contact-footer'
            spacing='tiny'
            horizontalAlignment='flex-end'
          >
            <Button
              iconOnly='delete'
              onClick={() => {
                if (mode === 'creating') {
                  abortEditing(() => setMode('viewing'), AbortIntent.CancelEdit)
                } else setIsDeleteDialogOpen(true)
              }}
            />
            <Button className='add-field-button' style={{ display: 'none' }}>
              Weiteres Feld
            </Button>
            <div className='footer-spacer' />
            <Button
              onClick={() => {
                abortEditing(() => setMode('viewing'), AbortIntent.CancelEdit)
              }}
            >
              Abbrechen
            </Button>
            <Tooltip
              content={
                props.missingName
                  ? 'Ein Kontakt muss immer entweder einen Vor- oder Nachnamen oder einen Firmennamen besitzen.'
                  : null
              }
              placement='left'
            >
              <Button
                disabled={props.missingName}
                variant='primary'
                onClick={() => {
                  switch (mode) {
                    case 'creating':
                      return props.createContact(draftContact)
                    case 'editing':
                      return editContact(draftContact)
                  }
                }}
              >
                {mode === 'creating' && 'Kontakt erstellen'}
                {mode === 'editing' && 'Fertig'}
              </Button>
            </Tooltip>
          </Flex>

          <AbortEditDialog
            isAbortingEdit={reasonForAborting === AbortIntent.CancelEdit}
            onAbort={() => setMode('viewing')}
            onCancel={cancelAbort}
          />
        </>
      ) : (
        <Flex className='contact-footer'>
          <Flex spacing='tiny'>
            <Button
              iconOnly='delete'
              onClick={() => setIsDeleteDialogOpen(true)}
            />
            <Button className='add-field-button' style={{ display: 'none' }}>
              Weiteres Feld
            </Button>
          </Flex>
          <div className='footer-spacer' />
          <Flex spacing='tiny'>
            <Button
              onClick={() => {
                setDraftContact({ ...activeContact } as KeyValueStore)
                setMode('editing')
              }}
            >
              Bearbeiten
            </Button>
            {contactDialogStore.isPickerMode && (
              <Button
                variant='primary'
                onClick={() =>
                  props.onContactPicked!(contactDialogStore.activeContact!)
                }
                disabled={!contactDialogStore.activeContact}
              >
                Kontakt auswählen
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <ConfirmDeleteContact
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        contactName={
          activeContact ? contactStore.nameOfContact(activeContact) : ''
        }
        onConfirm={() => deleteContact()}
      />
    </>
  )
}

export default observer(SingleContactFooter)

import { observer } from 'mobx-react'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useRootStore } from '../stores/RootStoreContext'

const AuthenticatedRoute = (routeProps: RouteProps) => {
  const { authenticationStore } = useRootStore()
  const { children, ...rest } = routeProps

  return (
    <Route {...rest}>
      {!authenticationStore.isAuthenticated ? children : <Redirect to='/' />}
    </Route>
  )
}

export default observer(AuthenticatedRoute)

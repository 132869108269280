import { createContext, useContext } from 'react'
import RootStore from './RootStore'

export let store: RootStore = new RootStore()
const StoreContext = createContext<RootStore | undefined>(undefined)
const RootStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const root = store ?? new RootStore()

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>
}

export const useRootStore = (): RootStore => {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }
  return context
}

export default RootStoreProvider

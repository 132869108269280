import { observer } from 'mobx-react'
import contactDialogStore from '../../../stores/ContactDialogStore'
import { categories } from '../../sidebar/ContactsCategories'
import KeyValuePair from '../KeyValuePair'

const ContactFieldCategory = () => {
  const { activeContact } = contactDialogStore

  return (
    <KeyValuePair
      fieldKey='Kategorie'
      fieldValue={
        activeContact?.category
          ? categories[activeContact?.category] ?? activeContact?.category
          : '–'
      }
    />
  )
}

export default observer(ContactFieldCategory)

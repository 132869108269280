import IActivity, { IActivityChanges, INewActivity } from '../model/Activity'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_HEADERS } from './NetworkingConstants'

const listActivitites = async (
  organisationId: string,
  contactId: string
): Promise<IActivity[]> => {
  const response = await fetch(
    `${BASE_URL}/contact/${contactId}/activities${accessQueryParameters(
      organisationId
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.activities) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.activities as IActivity[]
}

const getActivity = async (
  organisationId: string,
  contactId: string,
  activityId: string
): Promise<IActivity> => {
  const response = await fetch(
    `${BASE_URL}/contact/${contactId}/activities/${activityId}${accessQueryParameters(
      organisationId
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.activity) {
    switch (body.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 404:
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.activity as IActivity
}

const createActivity = async (
  activity: INewActivity,
  organisationId: string,
  contactId: string,
  authorId: string
): Promise<IActivity> => {
  const response = await fetch(
    `${BASE_URL}/contact/${contactId}/activities${accessQueryParameters(
      organisationId
    )}`,
    {
      method: 'POST',
      ...DEFAULT_HEADERS,
      body: JSON.stringify({
        ...activity,
        /** ...And of course just as temporary as in the backend: */
        authorId,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.activity) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.activity as IActivity
}

const updateActivity = async (
  changes: IActivityChanges,
  activityId: string,
  organisationId: string,
  contactId: string
): Promise<IActivity> => {
  const response = await fetch(
    `${BASE_URL}/contact/${contactId}/activities/${activityId}${accessQueryParameters(
      organisationId
    )}`,
    { method: 'PUT', ...DEFAULT_HEADERS, body: JSON.stringify(changes) }
  )
  const body = await response.json()

  if (!response.ok || !body.activity) {
    switch (body.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 404:
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.activity as IActivity
}

const deleteActivity = async (
  activityId: string,
  organisationId: string,
  contactId: string
): Promise<IActivity> => {
  const response = await fetch(
    `${BASE_URL}/contact/${contactId}/activities/${activityId}${accessQueryParameters(
      organisationId
    )}`,
    { method: 'DELETE', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.activity) {
    switch (response.status) {
      case 401:
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 404:
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.activity as IActivity
}

const Activities = {
  listActivitites,
  getActivity,
  createActivity,
  updateActivity,
  deleteActivity,
}

export default Activities

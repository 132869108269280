import {
  Button,
  DatePicker,
  Flex,
  Heading,
  InputField,
  Spacer,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import DetailSheet from '../../../../base-components/detail-sheet/DetailSheet'
import Editor from '../../../../base-components/editor/Editor'
import { ActivityType, INewActivity } from '../../../../model/Activity'
import { Contact } from '../../../../model/Contact'
import Activities from '../../../../networking/Activities'
import { useRootStore } from '../../../../stores/RootStoreContext'
import ActivityIcon from '../ActivityIcon'

interface NewActivitySheetProps {
  isOpen?: boolean
  onDismiss: () => void
  onCreation: () => void
  type: ActivityType
  contact: Contact
}

const titleForType = (type: ActivityType) => {
  switch (type) {
    case 'call':
      return 'Neuer Call'
    case 'deliverable':
      return 'Neues Deliverable'
    case 'email':
      return 'Neue E-Mail'
    case 'meeting':
      return 'Neuer Termin'
    case 'note':
      return 'Neue Notiz'
    case 'worklog':
      return 'Neuer Arbeitsbericht'
  }
}

const NewActivitySheet = (props: NewActivitySheetProps) => {
  const { authenticationStore, organisationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [title, setTitle] = useState<string>('')
  const [content, setContent] = useState<string | null>(null)
  const [date, setDate] = useState<Date>(new Date())

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const createActivity = async () => {
    if (title.trim() === '' || !content) return

    const activity: INewActivity = {
      activityDate: +date,
      activityType: props.type,
      title,
      content: {
        text: content,
      },
    }
    setIsSaving(true)

    try {
      await Activities.createActivity(
        activity,
        authenticationStore.organisationIdentifier!,
        props.contact.id,
        authenticationStore.currentUser!.id
      )
      props.onCreation()
      props.onDismiss()
    } catch (error: any) {
      notificationCenter.addNotification({
        variant: 'error',
        children: `Fehler beim Erstellen des neuen Eintrags. ${
          error.message ?? ''
        }`,
      })
    } finally {
      setIsSaving(false)
    }
  }

  const isAllowedToSetDate =
    props.type !== 'note' &&
    props.type !== 'worklog' &&
    props.type !== 'deliverable'

  return (
    <DetailSheet {...props} className='activity-create-sheet'>
      <Flex spacing='small'>
        <ActivityIcon type={props.type} />
        <Heading type='h3' bareTop>
          {titleForType(props.type)}
        </Heading>
      </Flex>
      <InputField
        value={title}
        onChange={setTitle}
        fullWidth
        placeholder='Titel eingeben'
      />
      {isAllowedToSetDate && (
        <DatePicker
          value={date}
          onChange={(selectedDate) => setDate(selectedDate!)}
          quickDates
        />
      )}
      <Editor
        content={content}
        onChange={setContent}
        placeholder='Notizen hier eingeben'
        users={organisationStore.membersAsEditorAtoms}
        tall
      />
      <Spacer direction='vertical' size='small' />
      <Button
        iconLeft='plus'
        variant='primary'
        isLoading={isSaving}
        onClick={() => createActivity()}
      >
        Erstellen
      </Button>
    </DetailSheet>
  )
}

export default observer(NewActivitySheet)

import { create } from 'mobx-persist'
import AuthenticationStore from './AuthenticationStore'
import ContactStore from './ContactStore'
import OrganisationStore from './OrganisationStore'
import TasksStore from './TasksStore'

const hydrate = create({
  storage: localStorage,
  jsonify: true,
})

const APPLICATION_STORE_PREFIX = 'crm-'

export class RootStore {
  authenticationStore: AuthenticationStore
  organisationStore: OrganisationStore
  contactStore: ContactStore
  tasksStore: TasksStore

  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.organisationStore = new OrganisationStore(this)
    this.contactStore = new ContactStore(this)
    this.tasksStore = new TasksStore(this)

    this.hydrateStores()
  }

  async hydrateStores() {
    await hydrate(
      APPLICATION_STORE_PREFIX + 'authenticationStore',
      this.authenticationStore
    )
    await hydrate(
      APPLICATION_STORE_PREFIX + 'organisationStore',
      this.organisationStore
    )
    await hydrate(APPLICATION_STORE_PREFIX + 'contactStore', this.contactStore)
    await hydrate(APPLICATION_STORE_PREFIX + 'tasksStore', this.tasksStore)

    this.authenticationStore.onHydrate()
    this.contactStore.onHydrate()
    this.organisationStore.onHydrate()
    this.tasksStore.onHydrate()
  }
}

export default RootStore

import {
  Flex,
  IconSize,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import IActivity from '../../../model/Activity'
import { Contact } from '../../../model/Contact'
import Activities from '../../../networking/Activities'
import useBackendQuery from '../../../networking/BackendQuery'
import { useRootStore } from '../../../stores/RootStoreContext'
import ActivitiesAddButton from './ActivitiesAddButton'
import ActivitiesTimeline from './ActivitiesTimeline'
import './ClientActivities.scss'
import ActivityDetailSheet from './detail/ActivityDetailSheet'

interface ClientActivitiesProps {
  contact?: Contact
}

const ClientActivities = (props: ClientActivitiesProps) => {
  const { authenticationStore } = useRootStore()
  const [activities, isLoading, error, invalidateActivities] = useBackendQuery(
    async () => {
      if (!props.contact) return undefined
      return await Activities.listActivitites(
        authenticationStore.organisationIdentifier!,
        props.contact?.id
      )
    }
  )

  const [selectedActivity, setSelectedActivity] = useState<IActivity>()
  const [isActivityDetailSheetOpen, setIsActivityDetailSheetOpen] =
    useState<boolean>(false)

  useEffect(() => {
    if (props.contact) invalidateActivities()
    setSelectedActivity(undefined)
  }, [props.contact])

  if (!props.contact)
    return (
      <div className='client-activities non-loaded no-contact-state'>
        <NonIdealState>
          Wähle einen Kontakt aus, um die Aktivitäten zu sehen.
        </NonIdealState>
      </div>
    )

  if (isLoading)
    return (
      <div className='client-activities non-loaded loading-state'>
        <Flex
          horizontalAlignment='center'
          verticalAlignment='center'
          fillContainer
        >
          <LoadingIndicator />
        </Flex>
      </div>
    )

  if (error || !activities)
    return (
      <div className='client-activities non-loaded error-state'>
        <NonIdealState icon='error' iconSize={IconSize.Small}>
          Die Aktivitäten konnten nicht geladen werden. {error?.message ?? ''}
        </NonIdealState>
      </div>
    )

  return (
    <div className='client-activities'>
      <div className='client-activities-actions'>
        <ActivitiesAddButton
          contact={props.contact}
          onActivityCreated={() => {
            invalidateActivities()
            setSelectedActivity(undefined)
            setIsActivityDetailSheetOpen(false)
          }}
        />
      </div>
      <ActivitiesTimeline
        activities={activities}
        onActivitySelected={(activity) => {
          setSelectedActivity(activity)
          setIsActivityDetailSheetOpen(true)
        }}
      />
      {selectedActivity && (
        <ActivityDetailSheet
          activity={selectedActivity}
          isOpen={isActivityDetailSheetOpen}
          onDismiss={() => setIsActivityDetailSheetOpen(false)}
          onDeleted={() => {
            invalidateActivities()
            setSelectedActivity(undefined)
          }}
        />
      )}
    </div>
  )
}

export default observer(ClientActivities)

import {
  Button,
  Flex,
  LoadingIndicator,
  NonIdealState,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import DetailSheet from '../../../../base-components/detail-sheet/DetailSheet'
import Editor from '../../../../base-components/editor/Editor'
import IActivity, { IEmailActivity } from '../../../../model/Activity'
import Activities from '../../../../networking/Activities'
import useBackendQuery from '../../../../networking/BackendQuery'
import { useRootStore } from '../../../../stores/RootStoreContext'
import ActivityDepiction from '../ActivityDepiction'
import ActivityConfirmDeleteDialog from './ActivityConfirmDeleteDialog'
import './ActivityDetailSheet.scss'
import ActivityEmailDetails from './ActivityEmailDetails'

interface ActivityDetailSheetProps {
  activity: IActivity
  isOpen?: boolean
  onDismiss: () => void
  onDeleted: () => void
}

const ActivityDetailSheet = (props: ActivityDetailSheetProps) => {
  const { authenticationStore, organisationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isEditingMode, setIsEditingMode] = useState<boolean>(false)
  const [editContent, setEditContent] = useState<any>()
  const [forceEditorRerenderKey, setForceEditorRerenderKey] =
    useState<number>(0)

  const [activity, isLoading, error, invalidateQuery] = useBackendQuery(
    () =>
      Activities.getActivity(
        authenticationStore.organisationIdentifier!,
        props.activity.contactId,
        props.activity.id
      ),
    (object) => setEditContent(object.content?.text)
  )

  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState<boolean>(false)

  const resetEditor = () => {
    setEditContent(activity?.content?.text)
    setIsEditingMode(false)
    setForceEditorRerenderKey(forceEditorRerenderKey + 1)
  }

  const saveChanges = async () => {
    setIsSavingChanges(true)
    try {
      const updatedActivity = await Activities.updateActivity(
        {
          content: {
            ...(activity?.content ?? {}),
            text: editContent,
          },
        },
        props.activity.id,
        authenticationStore.organisationIdentifier!,
        props.activity.contactId
      )
      notificationCenter.addNotification({
        children: 'Änderungen gespeichert',
        variant: 'success',
      })
      invalidateQuery(updatedActivity)
      setIsEditingMode(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        children: `Die Änderungen am Eintrag konnten nicht gespeichert werden. ${error.message}`,
        variant: 'error',
      })
    } finally {
      setIsSavingChanges(false)
    }
  }

  useEffect(() => {
    invalidateQuery()
  }, [props.activity.id])

  return (
    <DetailSheet {...props} className='activity-detail-sheet'>
      {isLoading ? (
        <LoadingIndicator />
      ) : error || !activity ? (
        <NonIdealState>
          Beim Laden ist ein Fehler aufgetreten. {error?.message}
        </NonIdealState>
      ) : (
        <>
          <ActivityDepiction activity={activity} />
          {activity.activityType === 'email' && (
            <ActivityEmailDetails activity={activity as IEmailActivity} />
          )}
          {(activity.content?.text || isEditingMode) && (
            <Editor
              key={forceEditorRerenderKey}
              content={editContent}
              viewingOnly={!isEditingMode}
              onChange={(content) => setEditContent(content)}
              tall={isEditingMode}
              users={organisationStore.membersAsEditorAtoms}
            />
          )}
          {!isEditingMode ? (
            <Flex className='activity-actions' spacing='tiny'>
              <Button iconLeft='edit' onClick={() => setIsEditingMode(true)}>
                Bearbeiten
              </Button>
              <Button
                iconLeft='delete'
                onClick={() => setIsDeleteConfirmDialogOpen(true)}
              >
                Löschen
              </Button>
            </Flex>
          ) : (
            <Flex className='activity-actions' spacing='tiny'>
              <Button
                iconLeft='check'
                variant='primary'
                onClick={() => saveChanges()}
                isLoading={isSavingChanges}
              >
                Änderungen speichern
              </Button>
              <Button onClick={() => resetEditor()} disabled={isSavingChanges}>
                Verwerfen
              </Button>
            </Flex>
          )}
          <ActivityConfirmDeleteDialog
            activity={activity}
            isOpen={isDeleteConfirmDialogOpen}
            onDismiss={() => setIsDeleteConfirmDialogOpen(false)}
            onDeleteCompleted={() => props.onDeleted()}
          />
        </>
      )}
    </DetailSheet>
  )
}

export default observer(ActivityDetailSheet)

import { ApplicationFrame } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import AuthenticateOutlookPluginPage from './pages/AuthenticateOutlookPluginPage'
import AuthenticationPage from './pages/AuthenticationPage'
import ClientsPage from './pages/clients/ClientsPage'
import NotFoundPage from './pages/NotFoundPage'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import UnauthenticatedRoute from './routes/UnauthenticatedRoute'
import { useRootStore } from './stores/RootStoreContext'
import { useQuery } from './utility/hooks'

const ApplicationSwitch = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const oneTimeToken = query.get('token')
  const organisationIdentifier = query.get('organisationIdentifier')

  return (
    <ApplicationFrame
      appTitle='Customer Central'
      identifier='customer-central'
      userMenu={authenticationStore.userMenu}
      history={history}
      location={location}
      headerProps={{
        onHomeClick: () => {
          document.location.href = 'https://staging.infinity.nextbusiness.ch/'
        },
      }}
    >
      <div className='application-switch'>
        <Switch>
          <AuthenticatedRoute
            path='/'
            exact
            redirectToIfUnauthenticated={
              oneTimeToken && organisationIdentifier
                ? `/login?token=${encodeURIComponent(
                    oneTimeToken
                  )}&organisationIdentifier=${encodeURIComponent(
                    organisationIdentifier
                  )}`
                : '/login'
            }
          >
            <Redirect to='/clients' />
          </AuthenticatedRoute>
          <UnauthenticatedRoute path='/login' exact>
            <AuthenticationPage />
          </UnauthenticatedRoute>
          <AuthenticatedRoute path='/clients' exact>
            <ClientsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute path='/authenticate-outlook-plugin' exact>
            <AuthenticateOutlookPluginPage />
          </AuthenticatedRoute>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </ApplicationFrame>
  )
}

export default observer(ApplicationSwitch)

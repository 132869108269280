import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import IActivity from '../../../../model/Activity'
import Activities from '../../../../networking/Activities'
import { useRootStore } from '../../../../stores/RootStoreContext'

interface ActivityConfirmDeleteDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onDeleteCompleted: () => void
  activity: IActivity
}

const ActivityConfirmDeleteDialog = (
  props: ActivityConfirmDeleteDialogProps
) => {
  const { authenticationStore } = useRootStore()

  const notificationCenter = useNotificationCenter()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteActivity = async () => {
    setIsDeleting(true)
    try {
      await Activities.deleteActivity(
        props.activity.id,
        authenticationStore.organisationIdentifier!,
        props.activity.contactId
      )
      props.onDismiss()
      props.onDeleteCompleted()
      notificationCenter.addNotification({
        children: 'Der Eintrag wurde gelöscht.',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children: `Der Eintrag konnte nicht gelöscht werden. ${error.message}`,
        variant: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title={`Möchtest du «${props.activity.title}» wirklich löschen?`}
      dismissable
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
          disabled: isDeleting,
        },
        {
          children: 'Unwiderruflich löschen',
          variant: 'destructive',
          onClick: () => deleteActivity(),
          isLoading: isDeleting,
        },
      ]}
    >
      Dieser Eintrag wird für alle in der Organisation unwiderruflich gelöscht
      werden.
    </Dialog>
  )
}

export default observer(ActivityConfirmDeleteDialog)

import { Remirror, useRemirror, EmojiPopupComponent } from '@remirror/react'
import React, { useCallback } from 'react'
import data from 'svgmoji/emoji.json'
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeBlockExtension,
  CodeExtension,
  EmojiExtension,
  HardBreakExtension,
  HeadingExtension,
  HistoryExtension,
  HorizontalRuleExtension,
  ItalicExtension,
  LinkExtension,
  OrderedListExtension,
  PositionerExtension,
  MentionAtomExtension,
  PlaceholderExtension,
  ShortcutsExtension,
  TaskListExtension,
} from 'remirror/extensions'
import EditorToolbar from './EditorToolbar'
import MentionComponent, {
  extraAttributes,
  MentionComponentProps,
} from './EditorMentions'
import 'remirror/styles/all.css'
import './Editor.scss'
import FloatingLinkToolbar from './EditorLinkMenu'

interface EditorProps extends Pick<MentionComponentProps, 'users' | 'tags'> {
  content?: any
  onChange?: (content: any) => void
  viewingOnly?: boolean
  placeholder?: string
  tall?: boolean
}

const Editor = (props: EditorProps) => {
  const extensions = useCallback(
    () => [
      new BoldExtension(),
      new BlockquoteExtension(),
      new CodeBlockExtension(),
      new CodeExtension(),
      new HardBreakExtension(),
      new HeadingExtension(),
      new HistoryExtension(),
      new HorizontalRuleExtension(),
      new ItalicExtension(),
      new LinkExtension({
        autoLink: true,
        selectTextOnClick: true,
        defaultTarget: '_blank',
      }),
      new PositionerExtension(),
      new PlaceholderExtension({ placeholder: props.placeholder }),
      new ShortcutsExtension(),
      new BulletListExtension(),
      new TaskListExtension(),
      new OrderedListExtension(),
      new MentionAtomExtension({
        matchers: [
          { name: 'at', char: '@', appendText: ' ' },
          { name: 'tag', char: '#', appendText: ' ' },
        ],
      }),
      new EmojiExtension({ plainText: false, data, moji: 'twemoji' }),
    ],
    [props.placeholder]
  )

  const { manager, state, setState } = useRemirror({
    extensions,
    extraAttributes,

    // This content is used to create the initial value. It is never referred to again after the first render.
    content: props.content,
  })

  return (
    <div
      className={
        'nextbusiness-editor' +
        (props.viewingOnly ? ' view-only' : '') +
        (props.tall ? ' tall' : '')
      }
    >
      <Remirror
        editable={!props.viewingOnly}
        autoRender='end'
        manager={manager}
        placeholder={props.placeholder}
        state={state}
        onChange={(parameter) => {
          setState(parameter.state)
          if (props.onChange) props.onChange(parameter.state.doc.toJSON())
        }}
      >
        {!props.viewingOnly && <EditorToolbar />}
        <EmojiPopupComponent />
        <MentionComponent users={props.users} tags={props.tags} />
        {!props.viewingOnly && <FloatingLinkToolbar />}
      </Remirror>
    </div>
  )
}

export default Editor

import {
  Button,
  Dialog,
  Flex,
  Heading,
  InputField,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Contact } from '../model/Contact'
import contactDialogStore from '../stores/ContactDialogStore'
import { useRootStore } from '../stores/RootStoreContext'
import './ContactsDialog.scss'
import ContactsListSegment from './list-tab/ContactsListSegment'
import ContactsCategories from './sidebar/ContactsCategories'
import SingleContactTab from './single-contact-tab/SingleContactTab'

export type Mode = 'viewing' | 'editing' | 'creating'
export enum AbortIntent {
  CancelEdit,
  SelectOtherContact,
  ExitContacts,
  None,
}

export type ContactsDialogMode = 'standalone' | 'picker'

interface ContactsDialogProps {
  mode?: ContactsDialogMode
  onContactPicked?: (contact: Contact) => void
}

const ContactsDialog = (props: ContactsDialogProps) => {
  const { contactStore } = useRootStore()

  const {
    isContactsOpen,
    setIsContactsOpen,
    mode,
    setMode,
    setActiveContact,
    setDraftContact,
    abortEditing,
    blankContact,
    setIsPickerMode,
  } = contactDialogStore

  const [wasContactFound, setWasContactFound] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [activeCategory, setActiveCategory] = useState<string>('all')

  useEffect(() => {
    if (isContactsOpen) {
      setActiveContact(undefined)
      setMode('viewing')
      setIsPickerMode(props.mode === 'picker')
    }
  }, [isContactsOpen, props.mode])

  const onContactPicked = (contact: Contact) => {
    setIsContactsOpen(false)
    if (props.onContactPicked) props.onContactPicked(contact)
  }

  return (
    <Dialog
      className='contacts-dialog'
      isOpen={isContactsOpen}
      dismissable
      onDismiss={() => {
        if (mode === 'creating' || mode === 'editing') {
          abortEditing(() => setIsContactsOpen(false), AbortIntent.CancelEdit)
        } else setIsContactsOpen(false)
      }}
    >
      <Flex>
        <div className='contacts-sidebar'>
          <Heading type='h2' divider bareTop>
            Kontakte
          </Heading>
          <ContactsCategories
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
          />
        </div>

        <div className='contacts-list'>
          <Flex
            direction='vertical'
            className='list-content'
            verticalAlignment='flex-start'
          >
            <InputField
              value={searchValue}
              onChange={setSearchValue}
              className='contacts-search'
              icon='search'
              placeholder='Suche'
            />
            {contactStore.allContacts.length > 0 ? (
              <ContactsListSegment
                setWasContactFound={setWasContactFound}
                searchQuery={searchValue}
                activeCategory={activeCategory}
              />
            ) : (
              <NonIdealState title='Noch keine Kontakte'>
                Erstelle zuerst einen Kontakt
              </NonIdealState>
            )}
            <Button
              iconLeft='add'
              variant='primary'
              className='new-contact-button'
              onClick={() => {
                setDraftContact(blankContact)
                setMode('creating')
                setActiveContact(undefined)
              }}
              disabled={mode !== 'viewing'}
            >
              Neuer Kontakt
            </Button>
          </Flex>
        </div>

        <SingleContactTab
          wasContactFound={wasContactFound}
          onContactPicked={onContactPicked}
        />
      </Flex>
    </Dialog>
  )
}

export default observer(ContactsDialog)

import { observer } from 'mobx-react'
import React from 'react'
import IActivity from '../../../model/Activity'
import { useRootStore } from '../../../stores/RootStoreContext'
import './ActivityDepiction.scss'
import ActivityIcon from './ActivityIcon'

interface ActivityDepictionProps {
  activity: IActivity
}

const ActivityDepiction = ({ activity }: ActivityDepictionProps) => {
  const { organisationStore } = useRootStore()

  return (
    <div className='activity-depiction'>
      <ActivityIcon type={activity.activityType} />
      <div className='depiction'>
        <span className='title'>{activity.title}</span>
        <span className='person'>
          {organisationStore.fullNameOfMember(activity.authorId)}
        </span>
      </div>
    </div>
  )
}

export default observer(ActivityDepiction)

import { Button } from '@nextbusiness/infinity-ui'
import React from 'react'
import IActivity from '../../../model/Activity'
import DateUtilities from '../../../utility/DateUtilites'
import './ActivitiesTimelineEntry.scss'
import ActivityDepiction from './ActivityDepiction'

interface ActivitiesTimelineEntryProps {
  activity: IActivity
  onClick?: () => void
}

const ActivitiesTimelineEntry = ({
  activity,
  onClick,
}: ActivitiesTimelineEntryProps) => (
  <Button className='activity-entry' variant='shell' onClick={onClick}>
    <ActivityDepiction activity={activity} />
    <span className='date'>
      {DateUtilities.dateFromISO(activity.activityDate).toLocaleDateString()}
    </span>
  </Button>
)

export default ActivitiesTimelineEntry

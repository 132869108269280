import { KeyValueStore } from '../utility/types'

export interface Contact {
  id: string
  companyName?: string
  firstname?: string
  lastname?: string
  address?: string
  postCode?: string
  position?: string
  email?: string
  mobile?: string
  phone?: string
  website?: string
  organisationId?: string
  category: string
  keywords?: string[]
}

export const translatedContactKeys: KeyValueStore = {
  address: 'Adresse',
  postCode: 'PLZ und Ort',
  email: 'E-Mail',
  mobile: 'Mobilnummer',
  phone: 'Telefonnummer',
  website: 'Webseite',
  keywords: 'Schlüsselwörter',
  category: 'Kategorie',
}

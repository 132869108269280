import { Option } from '@nextbusiness/infinity-ui'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { MentionAtomNodeAttributes } from 'remirror/extensions'
import Organisation from '../model/Organisation'
import OrganisationMember, { Level } from '../model/OrganisationMember'
import Authentication from '../networking/Authentication'
import RootStore from './RootStore'
import Store from './Store'

export default class OrganisationStore extends Store {
  @persist('object') @observable currentOrganisation: Organisation | null = null
  @persist('list') @observable members: OrganisationMember[] | null = []

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @computed
  get currentIdentifier() {
    return this.rootStore.authenticationStore.organisationIdentifier
  }

  loadOrganisationInformation() {
    if (!this.currentIdentifier) return

    this.loadCurrentOrganisationProfile()
    this.loadOrganisationMembers()
  }

  private async loadCurrentOrganisationProfile() {
    const organisation = await Authentication.organisationInformation(
      this.currentIdentifier!
    )
    runInAction(() => {
      this.currentOrganisation = organisation
    })
  }

  private async loadOrganisationMembers() {
    const members = await Authentication.membersForOrganisation(
      this.currentIdentifier!
    )
    runInAction(() => {
      this.members = members
    })
  }

  @action
  reset() {
    this.currentOrganisation = null
    this.members = []
  }

  onHydrate() {
    if (!this.currentIdentifier) return

    this.loadOrganisationInformation()
    this.rootStore.tasksStore.loadTasks()
  }

  get currentUserLevel(): Level | null {
    const user = this.members?.find(
      (member) =>
        member.id === this.rootStore.authenticationStore.currentUser?.id
    )
    if (!user) return null

    return user.level
  }

  get membersAsOptions(): Option[] {
    return (
      this.members
        ?.filter((member) => member.invitationStatus === 'accepted')
        .map((member) => ({
          label: this.fullNameOfMember(member.id!),
          value: member.id!,
        })) ?? []
    )
  }

  get membersAsEditorAtoms(): MentionAtomNodeAttributes[] | undefined {
    return this.members?.map((member) => ({
      id: member.id!,
      label: this.fullNameOfMember(member.id!),
    }))
  }

  fullNameOfMember(memberId: string): string {
    const member = this.members?.find(
      (teamMember) => teamMember.id === memberId
    )
    if (member) {
      return member.firstname + ' ' + member.lastname
    }
    return 'Unbekanntes Mitglied'
  }
}

import React, { useEffect, useState } from 'react'
import ClientsSidebar from './sidebar/ClientsSidebar'
import './ClientsPage.scss'
import ClientDetailView from './detail/ClientDetailView'
import ClientActivities from './activities/ClientActivities'
import { observer } from 'mobx-react'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import RequestPermission from '../../base-components/system/RequestPermission'
import { Permission } from '../../base-components/system/Permission'

const ClientsPage = () => {
  const { contactStore, authenticationStore } = useRootStore()
  const { activeContact } = contactDialogStore
  const [wasContactFound, setWasContactFound] = useState<boolean>(true)

  useEffect(() => {
    contactStore.loadContacts()
  }, [])

  if (!contactStore.hasContactsPermissions)
    return (
      <RequestPermission
        permissions={[Permission.ReadContacts, Permission.WriteContacts]}
        onPermissionDenied={() => document.location.reload()}
        onPermissionGranted={() => authenticationStore.logout()}
      />
    )

  return (
    <div>
      <div className='clients-page'>
        <ClientsSidebar
          wasContactFound={wasContactFound}
          setWasContactFound={setWasContactFound}
        />
        <ClientDetailView wasContactFound={wasContactFound} />
        <ClientActivities contact={activeContact} />
      </div>
    </div>
  )
}

export default observer(ClientsPage)

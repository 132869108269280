import { observer } from 'mobx-react'
import { Contact, translatedContactKeys } from '../../../model/Contact'
import contactDialogStore from '../../../stores/ContactDialogStore'
import { linkify } from '../../../utility/Utilities'
import KeyValuePair from '../KeyValuePair'

export const VISIBLE_CONTACT_FIELDS: (keyof Contact)[] = [
  'phone',
  'mobile',
  'email',
  'website',
  'address',
  'postCode',
]

const linkForField = (key: keyof Contact, value?: any) => {
  let link: string | undefined
  if (value && typeof value === 'string') {
    switch (key) {
      case 'email':
        link = `mailto:${value}`
        break
      case 'website':
        link = linkify(value)
        break
    }
  }
  return link
}

const ContactFieldList = () => {
  const { activeContact } = contactDialogStore

  if (!activeContact) {
    return null
  }
  return (
    <>
      {VISIBLE_CONTACT_FIELDS.filter((field) =>
        Object.keys(activeContact).includes(field)
      ).map(
        (fieldKey) =>
          activeContact[fieldKey] && (
            <KeyValuePair
              key={fieldKey}
              link={linkForField(fieldKey, activeContact[fieldKey])}
              fieldKey={translatedContactKeys[fieldKey]}
              fieldValue={activeContact[fieldKey]}
            />
          )
      )}
    </>
  )
}

export default observer(ContactFieldList)

import { Flex, Text } from '@nextbusiness/infinity-ui'
import './KeyValuePair.scss'

interface KeyValuePairProps {
  fieldKey: string
  fieldValue: string | React.ReactNode
  link?: string
  className?: string
}

const KeyValuePair = (props: KeyValuePairProps) => (
  <Flex className={`key-value-pair ${props.className || ''}`}>
    <Text type='inline' className='key'>
      {props.fieldKey}
    </Text>
    <Text type='inline' className='value'>
      {props.link ? (
        <a className='link' href={props.link} target='_blank' rel='noreferrer'>
          {props.fieldValue}
        </a>
      ) : (
        props.fieldValue
      )}
    </Text>
  </Flex>
)

export default KeyValuePair

import { Icon, IconItem } from '@nextbusiness/infinity-ui'
import React from 'react'
import { ActivityType } from '../../../model/Activity'
import './ActivityIcon.scss'

interface ActivityIconProps {
  type: ActivityType
}

const iconForType = (type: ActivityType): IconItem => {
  switch (type) {
    case 'call':
      return 'phone'
    case 'deliverable':
      return 'checked-checkbox'
    case 'email':
      return 'envelope'
    case 'meeting':
      return 'meeting-time'
    case 'note':
      return 'note'
    case 'worklog':
      return 'speaker-notes'
  }
}

const ActivityIcon = (props: ActivityIconProps) => (
  <div className={`activity-icon type-${props.type}`}>
    <Icon icon={iconForType(props.type)} size={22} />
  </div>
)

export default ActivityIcon

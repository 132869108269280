import { Button, Flex } from '@nextbusiness/infinity-ui'
import { KeyValueStore } from '../../utility/types'
import './ContactsCategories.scss'

interface ContactsCategoriesProps {
  activeCategory: string
  setActiveCategory: (activeCategory: string) => void
}

export const categories: KeyValueStore = {
  all: 'Alle',
  clients: 'Kunden',
  suppliers: 'Lieferanten',
  partners: 'Partner',
  team: 'Team',
}

const ContactsCategories = (props: ContactsCategoriesProps) => {
  return (
    <Flex direction='vertical' className='contacts-categories'>
      {Object.keys(categories).map((key) => (
        <Button
          key={key}
          variant='shell'
          className={`category-item ${
            props.activeCategory === key ? 'active' : ''
          }`}
          onClick={() => props.setActiveCategory(key)}
        >
          {categories[key]}
        </Button>
      ))}
    </Flex>
  )
}

export default ContactsCategories

import Organisation from '../model/Organisation'
import OrganisationMember from '../model/OrganisationMember'
import User from '../model/User'
import { BackendError, ErrorType } from './Errors'
import {
  DEFAULT_ERROR,
  DEFAULT_HEADERS,
  FABRIC_BASE_URL,
} from './NetworkingConstants'

export let currentApplicationAccessToken: string | undefined
export let currentUserDataToken: string | undefined

/**
 * Sets the applicationAccessToken that is passed with every request and the userDataToken which is needed for datalake requests
 * @param applicationAccessToken Infinity backend token
 * @param userDataToken Infinity backend token
 */
const setCurrentTokens = (
  applicationAccessToken: string,
  userDataToken: string
) => {
  if (!applicationAccessToken || !userDataToken) return
  currentApplicationAccessToken = applicationAccessToken
  currentUserDataToken = userDataToken
}

/**
 * Returns the a query string (starting with "?") with all necessary parameters to
 * send an authorised request to the backend
 * @param organisationIdentifier  ID of the organisation from which the app was started
 */
export const accessQueryParameters = (organisationIdentifier: string) =>
  `?applicationAccessToken=${currentApplicationAccessToken}&organisation=${organisationIdentifier}`

/**
 * Gets the applicationAccessToken and the userDataToken using a one-time-access-token
 * @param organisationIdentifier ID of the organisation from which the app was started
 * @param applicationIdentifier Name of the application that was started
 * @param token One-time-access-token
 * @returns
 */
const getAppAccessAndUserDataToken = async (
  organisationIdentifier: string,
  applicationIdentifier: string,
  token: string
): Promise<[string, string]> => {
  const response = await fetch(
    FABRIC_BASE_URL +
      `/organisation/${organisationIdentifier}/application/${encodeURIComponent(
        applicationIdentifier
      )}/authenticate?token=${token}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.token) {
    switch (body.message) {
      case 'Unauthorised':
      case 'jwt expired':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      case 'This application does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'No active subscription':
        throw new BackendError(ErrorType.InsufficientSubscription, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return [body.token, body.userDataToken]
}

/**
 * Retrieves the user profile (containing name, email, ...) for the
 * currently authenticated user
 * @throws {BackendError} Possibly Unauthorised
 * @throws {Error} Can throw a generic error with a message
 */
const retrieveCurrentUser = async (): Promise<User> => {
  const response = await fetch(
    FABRIC_BASE_URL +
      '/user/current?applicationAccessToken=' +
      currentApplicationAccessToken +
      '&userDataToken=' +
      currentUserDataToken,
    DEFAULT_HEADERS
  )
  const body = await response.json()

  if (!response.ok || !body.user) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.user as User
}
/**
 * Returns the organisation details for a given organisation
 * @param organisationIdentifier Identifier for which the informations should be returned
 */
const organisationInformation = async (
  organisationIdentifier: string
): Promise<Organisation> => {
  const response = await fetch(
    FABRIC_BASE_URL +
      '/organisation/' +
      organisationIdentifier +
      accessQueryParameters(organisationIdentifier),
    DEFAULT_HEADERS
  )
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.organisation as Organisation
}

/**
 * Returns a list of members within a given organisation
 * @param organisationIdentifier ID of the organisation containing the members
 */
const membersForOrganisation = async (
  organisationIdentifier: string
): Promise<OrganisationMember[]> => {
  const response = await fetch(
    FABRIC_BASE_URL +
      '/organisation/' +
      organisationIdentifier +
      '/members' +
      accessQueryParameters(organisationIdentifier),
    DEFAULT_HEADERS
  )
  const body = await response.json()

  if (!response.ok || !body.members) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message ?? DEFAULT_ERROR)
    }
  }
  return body.members as OrganisationMember[]
}

const Authentication = {
  setCurrentTokens,
  getAppAccessAndUserDataToken,
  retrieveCurrentUser,
  membersForOrganisation,
  organisationInformation,
}

export default Authentication

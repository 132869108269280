import { Button } from '@nextbusiness/infinity-ui'
import React, { useEffect, useState } from 'react'
import './DetailSheet.scss'

export interface DetailSheetProps {
  isOpen?: boolean
  onDismiss: () => void

  children?: React.ReactNode
  className?: string
}

const ANIMATION_TRANSITION_OUT_DELAY = 300
const ANIMATION_TRANSITION_IN_DELAY = 50

const DetailSheet = (props: DetailSheetProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const [isAnimating, setIsAnimating] = useState<boolean>(true)

  useEffect(() => {
    if (!props.isOpen && !isHidden) {
      setIsAnimating(true)
      const timeout = window.setTimeout(() => {
        setIsHidden(true)
        setIsAnimating(false)
      }, ANIMATION_TRANSITION_OUT_DELAY)
      return () => window.clearInterval(timeout)
    } else {
      setIsAnimating(true)
      setIsHidden(false)

      const timeout = window.setTimeout(() => {
        setIsAnimating(false)
      }, ANIMATION_TRANSITION_IN_DELAY)
      return () => window.clearInterval(timeout)
    }
  }, [props.isOpen])

  if (isHidden) return null

  return (
    <div
      className={
        'detail-sheet-container' +
        (props.className ? ` ${props.className}` : '') +
        (isAnimating ? ' animating' : '')
      }
    >
      <div className='detail-sheet'>
        <div className='detail-sheet-inner'>
          <Button
            className='detail-sheet-dismiss'
            aria-label='Schliessen'
            iconOnly='dismiss'
            variant='quaternary'
            onClick={props.onDismiss}
          />
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default DetailSheet

import React from 'react'
import IActivity from '../../../model/Activity'
import ActivitiesTimelineEntry from './ActivitiesTimelineEntry'
import './ActivitiesTimeline.scss'
import DateUtilities from '../../../utility/DateUtilites'
import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'

interface ActivitiesTimelineProps {
  activities: IActivity[]
  onActivitySelected: (activity: IActivity) => void
}

const ActivitiesTimeline = (props: ActivitiesTimelineProps) => {
  const activitiesGroupedByTimeframe = React.useMemo(() => {
    const accumulator: TypedKeyValueStore<IActivity[]> = {}

    props.activities
      .sort((a, b) => {
        const dateA = DateUtilities.dateFromISO(a.activityDate)
        const dateB = DateUtilities.dateFromISO(b.activityDate)

        return dateA < dateB ? 1 : -1
      })
      .forEach((activity) => {
        const dateTime = DateTime.fromISO(activity.activityDate)

        const isDateThisYear = dateTime.year === DateTime.now().year
        const isDateThisMonth =
          isDateThisYear && dateTime.month === DateTime.now().month
        const isDateThisWeek =
          isDateThisYear && dateTime.weekNumber === DateTime.now().weekNumber
        const isDateYesterday =
          isDateThisWeek && dateTime.day === DateTime.now().day - 1
        const isDateToday =
          isDateThisWeek && dateTime.day === DateTime.now().day
        const isDateInFuture = dateTime.toMillis() > +new Date()

        let date

        if (isDateInFuture) date = 'Zukünftig'
        else if (isDateToday) date = 'Heute'
        else if (isDateYesterday) date = 'Gestern'
        else if (isDateThisWeek) date = 'Diese Woche'
        else if (isDateThisMonth) date = 'Diesen Monat'
        else
          date = dateTime.toLocaleString({
            localeMatcher: 'lookup',
            month: 'long',
            year: isDateThisYear ? undefined : 'numeric',
          })

        if (!accumulator[date]) accumulator[date] = [activity]
        else accumulator[date].push(activity)
      })

    return Object.keys(accumulator).map((month) => {
      return {
        id: month,
        title: month,
        activities: accumulator[month],
      }
    })
  }, [props.activities])

  return (
    <div className='activities-timeline'>
      {activitiesGroupedByTimeframe.map((timeframe) => (
        <div className='activities-timeline-timeframe' key={timeframe.id}>
          <div className='timeframe-title'>{timeframe.title}</div>
          <div className='timeframe-items'>
            {timeframe.activities.map((activity, index) => (
              <React.Fragment key={activity.id}>
                <ActivitiesTimelineEntry
                  activity={activity}
                  onClick={() => props.onActivitySelected(activity)}
                />
                {index !== timeframe.activities.length - 1 && (
                  <div className='separator' />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ActivitiesTimeline

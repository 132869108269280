import React from 'react'
import { IEmailActivity } from '../../../../model/Activity'
import DateUtilities from '../../../../utility/DateUtilites'
import './ActivityEmailDetails.scss'

interface ActivityEmailDetailsProps {
  activity: IEmailActivity
}

const TYPOGRAPHY_STYLES =
  'html, body { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; -webkit-font-smoothing: antialiased; } a { color: #0f913a; }'
const SCROLLBAR_MIXIN =
  'body,html{scrollbar-width:thin;scrollbar-color:#708090}::-webkit-scrollbar{width:20px;height:20px}::-webkit-scrollbar-thumb{background:#708090;border-radius:20px;visibility:visible;border:7.5px solid transparent;background-clip:padding-box}::-webkit-scrollbar-track{background:transparent}'
const DEFAULT_STYLES = `<style>${TYPOGRAPHY_STYLES}${SCROLLBAR_MIXIN}</style>`

const ActivityEmailDetails = (props: ActivityEmailDetailsProps) => (
  <div className='activity-email-details'>
    <div className='email-headers'>
      {props.activity.content?.from && (
        <div className='email-header'>
          <span className='title'>Von</span>
          <span className='information'>
            {props.activity.content?.from?.displayName} (
            {props.activity.content?.from?.emailAddress})
          </span>
        </div>
      )}
      {props.activity.content?.to && (
        <div className='email-header'>
          <span className='title'>An</span>
          <span className='information'>
            {props.activity.content?.to
              ?.map(
                (recipient) =>
                  `${recipient.displayName} (${recipient.emailAddress})`
              )
              .join(', ')}
          </span>
        </div>
      )}
      <div className='email-header'>
        <span className='title'>Erhalten am</span>
        <span className='information'>
          {DateUtilities.dateFromISO(
            props.activity.activityDate
          ).toLocaleString('de-CH', {
            dateStyle: 'full',
            timeStyle: 'short',
          })}
        </span>
      </div>
    </div>
    {props.activity.content?.body && (
      <iframe
        /** TODO: Replace this iframe with view-only editor instead.
         * Might be better for input sanitization. */
        srcDoc={props.activity.content.body + DEFAULT_STYLES}
        title='E-Mail'
        className='email-preview'
      />
    )}
  </div>
)

export default ActivityEmailDetails

import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import '../styles/UtilityPages.scss'

interface NotFoundPageProps {
  inline?: boolean
  returnLink?: string
  returnText?: string
}

const NotFoundPage = (props: NotFoundPageProps) => {
  const history = useHistory()

  return (
    <div className={props.inline ? 'inline-404' : 'utility-page page-404'}>
      <Flex
        fillContainer
        horizontalAlignment='center'
        verticalAlignment='center'
      >
        <NonIdealState
          icon='document'
          title='Diese Seite existiert nicht'
          actions={[
            {
              onClick: () => history.push(props.returnLink ?? '/'),
              children: props.returnText ?? 'Zurück zur Startseite',
            },
          ]}
        >
          <Flex direction='vertical'>
            <Text type='paragraph'>
              Falls du durch einen Link auf diese Seite gekommen bist oder
              weitergeleitet wurdest, haben wir die Seite womöglich entfernt
              oder verschoben.
            </Text>
          </Flex>
        </NonIdealState>
      </Flex>
    </div>
  )
}

export default NotFoundPage

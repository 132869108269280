import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ORIGIN } from '../../networking/NetworkingConstants'
import { useRootStore } from '../../stores/RootStoreContext'
import { InfinityResponse } from './InfinityBaseMessaging'
import { Permission } from './Permission'
import './RequestPermission.scss'

interface RequestPermissionProps {
  permissions: Permission[]
  onPermissionGranted?: () => void
  onPermissionDenied?: () => void
}

const MODAL_TRANSITION_DELAY = 1000
const APPLICATION_IDENTIFIER = 'ch.nextbusiness.infinity.crm'

const RequestPermission = (props: RequestPermissionProps) => {
  const { authenticationStore } = useRootStore()
  const [isModalActive, setIsModalActive] = useState<boolean>(true)

  const handleEventMessage = (event: MessageEvent<any>) => {
    if (event.origin !== ORIGIN) return

    const payload = JSON.parse(event.data) as InfinityResponse
    switch (payload.type) {
      case 'infinity.response.success':
        if (props.onPermissionGranted) props.onPermissionGranted()
        break
      case 'infinity.response.cancel':
        if (props.onPermissionDenied) props.onPermissionDenied()
        break
    }
    if (payload.shouldDismissFrame)
      setTimeout(() => setIsModalActive(false), MODAL_TRANSITION_DELAY)
  }

  useEffect(() => {
    window.addEventListener('message', handleEventMessage, false)
    return () =>
      window.removeEventListener('message', handleEventMessage, false)
  })

  return isModalActive
    ? ReactDOM.createPortal(
        <div className='request-permission-flow'>
          <iframe
            src={`${ORIGIN}/system/ask-permission?app=${APPLICATION_IDENTIFIER}&permissions=${props.permissions
              .map((permission) => encodeURIComponent(permission))
              .join(',')}&organisation=${
              authenticationStore.organisationIdentifier
            }`}
            title='Zugriff erteilen für App'
          />
        </div>,
        document.getElementById('frame-root')!
      )
    : null
}

export default RequestPermission

import { DateTime, DurationInput } from 'luxon'

const startOfDay = (date: Date) => {
  return DateTime.fromJSDate(date).startOf('day').toJSDate()
}

const endOfDay = (date: Date) => {
  return DateTime.fromJSDate(date).endOf('day').toJSDate()
}

const dateSubtract = (date: Date, amount: DurationInput) => {
  return DateTime.fromJSDate(date).minus(amount).toJSDate()
}

const dateAdd = (date: Date, amount: DurationInput) => {
  return DateTime.fromJSDate(date).plus(amount).toJSDate()
}

const dateFromISO = (isoDate: string) => {
  return DateTime.fromISO(isoDate, {
    zone: 'Europe/Zurich',
  }).toJSDate()
}

const DateUtilities = {
  startOfDay,
  endOfDay,
  dateFromISO,
  dateSubtract,
  dateAdd,
}

export default DateUtilities

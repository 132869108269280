import { Button, Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { Contact } from '../../model/Contact'
import Contacts from '../../networking/Contacts'
import { ErrorType } from '../../networking/Errors'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import { KeyValueStore } from '../../utility/types'
import { AbortIntent } from '../ContactsDialog'
import AbortEditDialog from './AbortEditDialog'
import ContactEditor from './ContactEditor'
import ContactViewer from './ContactViewer'
import ProfileHeader from './ProfileHeader'
import SingleContactFooter from './SingleContactFooter'
import './SingleContactTab.scss'

interface SingleContactTabProps {
  wasContactFound: boolean
  onContactPicked?: (contact: Contact) => void
}

const SingleContactTab = (props: SingleContactTabProps) => {
  const { authenticationStore, contactStore } = useRootStore()
  const { cancelAbort } = contactDialogStore

  const [localErrorMessage, setLocalErrorMessage] = useState<string>()

  const {
    setIsContactsOpen,
    mode,
    setMode,
    activeContact,
    setActiveContact,
    draftContact,
    reasonForAborting,
    abortEditing,
    errorMessage,
  } = contactDialogStore

  const missingName =
    !draftContact['firstname'] &&
    !draftContact['lastname'] &&
    !draftContact['companyName']

  const createContact = async (newContact: KeyValueStore) => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    try {
      setActiveContact(
        await Contacts.createContact(
          authenticationStore.organisationIdentifier,
          authenticationStore.applicationAccessToken,
          newContact as unknown as Contact
        )
      )
      contactStore.loadContacts()
      setLocalErrorMessage(undefined)
      setMode('viewing')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.MissingRequiredFields:
          return setLocalErrorMessage(
            'Ein Kontakt muss immer entweder einen Vor- oder Nachnamen oder einen Firmennamen besitzen.'
          )
        default:
          return setLocalErrorMessage(error.message)
      }
    }
  }

  return (
    <Flex direction='vertical' className='single-contact-tab'>
      <Button
        iconOnly='dismiss'
        variant='quaternary'
        className='contact-dismiss'
        onClick={() => {
          if (mode === 'creating' || mode === 'editing') {
            abortEditing(
              () => setIsContactsOpen(false),
              AbortIntent.ExitContacts
            )
          } else {
            setIsContactsOpen(false)
          }
        }}
      />
      <AbortEditDialog
        isAbortingEdit={reasonForAborting === AbortIntent.ExitContacts}
        onAbort={() => setIsContactsOpen(false)}
        onCancel={cancelAbort}
      />
      {errorMessage ? (
        <NonIdealState title={errorMessage} />
      ) : activeContact || mode === 'creating' ? (
        <>
          <ProfileHeader />
          {mode !== 'viewing' ? (
            <ContactEditor
              missingName={missingName}
              localErrorMessage={localErrorMessage}
            />
          ) : (
            <ContactViewer localErrorMessage={localErrorMessage} />
          )}
          <SingleContactFooter
            missingName={missingName}
            createContact={createContact}
            onContactPicked={props.onContactPicked}
            setLocalErrorMessage={setLocalErrorMessage}
          />
        </>
      ) : (
        <NonIdealState title='Kein Kontakt ausgewählt' />
      )}
    </Flex>
  )
}

export default observer(SingleContactTab)

import { Button, Flyout, TitledButtonGroups } from '@nextbusiness/infinity-ui'
import React, { useState } from 'react'
import { ActivityType } from '../../../model/Activity'
import { Contact } from '../../../model/Contact'
import './ActivitiesAddButton.scss'
import NewActivitySheet from './creation/NewActivitySheet'

interface ActivitiesAddButtonProps {
  contact: Contact
  onActivityCreated: () => void
}

const ActivitiesAddButton = (props: ActivitiesAddButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const [isAddingActivity, setIsAddingActivity] = useState<boolean>()
  const [selectedActivityType, setSelectedActivityType] =
    useState<ActivityType>()

  const addActivity = (type: ActivityType) => {
    setSelectedActivityType(type)
    setIsAddingActivity(true)
  }

  return (
    <>
      {selectedActivityType && (
        <NewActivitySheet
          contact={props.contact}
          onCreation={props.onActivityCreated}
          isOpen={isAddingActivity}
          onDismiss={() => setIsAddingActivity(false)}
          type={selectedActivityType}
        />
      )}
      <Flyout
        className='add-activity-menu'
        isActive={isMenuOpen}
        setIsActive={setIsMenuOpen}
        trigger={
          <Button
            iconLeft='plus'
            variant='primary'
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            Neuer Eintrag
          </Button>
        }
        triggerIsButton
      >
        <TitledButtonGroups
          closeMenu={() => setIsMenuOpen(false)}
          groups={[
            {
              title: '',
              buttons: [
                {
                  text: 'Call',
                  onClick: () => addActivity('call'),
                  icon: 'phone',
                },
                {
                  text: 'E-Mail',
                  onClick: () => addActivity('email'),
                  icon: 'envelope',
                },
                {
                  text: 'Termin',
                  onClick: () => addActivity('meeting'),
                  icon: 'meeting-time',
                },
                {
                  text: 'Deliverable',
                  onClick: () => addActivity('deliverable'),
                  icon: 'checked-checkbox',
                },
                {
                  text: 'Notiz',
                  onClick: () => addActivity('note'),
                  icon: 'note',
                },
                {
                  text: 'Arbeitsbericht',
                  onClick: () => addActivity('worklog'),
                  icon: 'speaker-notes',
                },
              ],
            },
          ]}
        />
      </Flyout>
    </>
  )
}

export default ActivitiesAddButton

import { Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useRootStore } from '../stores/RootStoreContext'

let isReadyForAuthentication = false

const AuthenticateOutlookPluginPage = () => {
  const { authenticationStore } = useRootStore()
  const [crmAddInBase, setCrmAddInBase] = useState<string>()

  const attemptAuth = (): boolean => {
    if (
      !authenticationStore.isHydrated ||
      !authenticationStore.isAuthenticated ||
      !authenticationStore.currentUser
    )
      return false

    window.top?.postMessage(
      JSON.stringify({
        action: 'authenticate-outlook-add-in',
        auth: {
          APPLICATION_ACCESS_TOKEN: authenticationStore.applicationAccessToken,
          ORGANISATION_IDENTIFIER: authenticationStore.currentUser?.id,
          USER_ID: authenticationStore.organisationIdentifier,
          USER_DATA_TOKEN: authenticationStore.userDataToken,
        },
      })
    )
    return true
  }

  const handleEventMessage = (event: MessageEvent<any>) => {
    if (event.origin === window.origin) return

    const allowedOrigins = [
      'https://crm.infinity.nextbusiness.ch',
      'https://add-in.crm.infinity.nextbusiness.ch',
      'https://localhost:3000',
      'http://localhost:3000',
    ]
    if (!allowedOrigins.includes(event.origin)) return

    try {
      const payload = JSON.parse(JSON.parse(event.data).messageContent)
      switch (payload.action) {
        case 'authenticate-outlook-add-in':
          setCrmAddInBase(event.origin)
          if (!attemptAuth()) isReadyForAuthentication = true
          break
      }
    } catch {}
  }

  useEffect(() => {
    window.addEventListener('message', handleEventMessage, false)
    return () =>
      window.removeEventListener('message', handleEventMessage, false)
  })

  useEffect(() => {
    if (
      authenticationStore.isHydrated &&
      authenticationStore.isAuthenticated &&
      authenticationStore.currentUser
    ) {
      if (isReadyForAuthentication) {
        attemptAuth()
      }
    }
  }, [
    authenticationStore.isHydrated,
    authenticationStore.isAuthenticated,
    authenticationStore.currentUser,
  ])

  return (
    <div>
      <Flex
        fillContainer
        horizontalAlignment='center'
        verticalAlignment='center'
        style={{ height: '90vh' }}
      >
        <LoadingIndicator loadingText='Einen Moment bitte.' />
        <div style={{ display: 'none' }}>
          {authenticationStore.isHydrated &&
          authenticationStore.isAuthenticated &&
          authenticationStore.currentUser &&
          crmAddInBase ? (
            <iframe
              src={`${crmAddInBase}/taskpane.html?r=${
                authenticationStore.applicationAccessToken
              }&o=${authenticationStore.organisationIdentifier}&u=${
                authenticationStore.currentUser!.id
              }`}
              title='Authentifizierung wird gesetzt.'
            />
          ) : null}
        </div>
      </Flex>
    </div>
  )
}

export default observer(AuthenticateOutlookPluginPage)
